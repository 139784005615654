import React from "react"
import styled from "styled-components"
import { Bar3D, Column2D } from "./Charts"
import DC from "../../data/covid/COVIDdc22042021.json"
import rea from "../../data/covid/COVIDrea22042021.json"
import hosp from "../../data/covid/COVIDhosp22042021.json"
import { CovidItemsMenu } from "./index"
import BackTopArrow from "../BackTopArrow"
import { isBrowser } from "../../constants/isBrowser"

const Covid = () => {
  const [topic, setTopic] = React.useState("1")
  const [showLink, setShowLink] = React.useState(false)
  const toggleTopic = number => {
    setTopic(number)
  }

  if (isBrowser() === true) {
    window.addEventListener("scroll", function () {
      const scrollHeight = window.pageYOffset
      // console.log(scrollHeight)
      if (scrollHeight > 1200) {
        setShowLink(true)
      } else {
        setShowLink(false)
      }
    })
  }
  return (
    <Wrapper>
      <CovidItemsMenu topic={topic} toggleTopic={toggleTopic} />
      <div className="content">
        {topic === "1" && (
          <>
            <div className="title">
              <h4>COVID-19: personnes hospitalisées par âge</h4>
            </div>
            <div className="sub-title s-b">
              <p>Chiffres au 22 avril.</p>
              <p>
                Source:{" "}
                <a href="https://geodes.santepubliquefrance.fr/#c=indicator">
                  Santé publique France
                </a>
              </p>
            </div>
            {typeof document !== "undefined" && (
              <>
                {hosp.map((area, i) => {
                  // console.log(area["region"])
                  const name = area["region"]
                  const chartData = Object.keys(area)
                    .slice(1)
                    .map(item => {
                      return { label: item, value: area[item] }
                    })
                  return <Column2D key={i} data={chartData} title={name} />
                })}
              </>
            )}
          </>
        )}
        {topic === "2" && (
          <>
            <div className="title">
              <h4>COVID-19: personnes en soins critiques par âge</h4>
            </div>
            <div className="sub-title s-b">
              <p>Chiffres au 22 avril.</p>
              <p>
                Source:{" "}
                <a href="https://geodes.santepubliquefrance.fr/#c=indicator">
                  Santé publique France
                </a>
              </p>
            </div>
            {typeof document !== "undefined" && (
              <>
                {rea.map((area, i) => {
                  // console.log(area["region"])
                  const name = area["region"]
                  const chartData = Object.keys(area)
                    .slice(1)
                    .map(item => {
                      return { label: item, value: area[item] }
                    })
                  return <Column2D key={i} data={chartData} title={name} />
                })}
              </>
            )}
          </>
        )}
        {topic === "3" && (
          <>
            <div className="title">
              <h4>COVID-19: décès par âge</h4>
            </div>
            <div className="sub-title s-b">
              <p>Chiffres au 22 avril.</p>
              <p>
                Source:{" "}
                <a href="https://geodes.santepubliquefrance.fr/#c=indicator">
                  Santé publique France
                </a>
              </p>
            </div>

            {typeof document !== "undefined" && (
              <>
                {DC.map((area, i) => {
                  // console.log(area["region"])
                  const name = area["region"]
                  const chartData = Object.keys(area)
                    .slice(1)
                    .map(item => {
                      return { label: item, value: area[item] }
                    })
                  // console.log(chartData)
                  return <Column2D key={i} data={chartData} title={name} />
                })}
              </>
            )}
          </>
        )}
      </div>
      <BackTopArrow showLink={showLink} />
    </Wrapper>
  )
}

const Wrapper = styled.section`
  .content {
    padding: 2rem 0;
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sub-title {
    margin-left: 1rem;
    a {
      /* color: red; */
      color: var(--clr-black);
    }
  }
`

export default Covid
