import React from "react"
import styled from "styled-components"

const CovidItemsMenu = ({ topic, toggleTopic }) => {
  return (
    <Wrapper>
      <div className="menu-center">
        <button
          type="button"
          className={`${topic === "1" ? "selected topic" : "topic"}`}
          onClick={() => {
            toggleTopic("1")
          }}
        >
          <h4>Personnes hospitalisées</h4>
        </button>
        <button
          type="button"
          className={`${topic === "2" ? "selected topic" : "topic"}`}
          onClick={() => {
            toggleTopic("2")
          }}
        >
          <h4>Personnes en soins critiques</h4>
        </button>
        <button
          type="button"
          className={`${topic === "3" ? "selected topic" : "topic"}`}
          onClick={() => {
            toggleTopic("3")
          }}
        >
          <h4>Personnes décédées</h4>
        </button>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;

  .menu-center {
    width: 90vw;
    max-width: var(--max-width);
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .topic {
    width: 100%;
    height: 2rem;
    border: 2px solid var(--clr-black);
    border-top: none;
    border-left: none;
    transition: var(--transition);
    background-color: transparent;
    cursor: pointer;
    outline: none;
    position: relative;
    h4 {
      position: absolute;
      left: 1rem;
      top: 0.5rem;
      transition: var(--transition);
    }
  }
  .selected {
    background-color: var(--clr-violet);
    h4 {
      color: var(--clr-white);
    }
  }
  .topic:hover {
    background-color: var(--clr-black);
    h4 {
      color: var(--clr-white);
    }
  }
  @media screen and (max-width: 992px) {
    height: 6rem;
    .menu-center {
      width: 95vw;
      flex-direction: column;
    }
    .topic {
      /* height: auto; */
    }
  }
`

export default CovidItemsMenu
